import React from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
//components
import Layout from 'components/ui/layout';
//parser
import parse from "html-react-parser";

//styled
const CaseStudiesWrap = styled.section`
  position: relative;
`;

const CaseStudiesContent = styled.div`
  position: relative;
`;

const CaseStudies = () => {

  const data = useStaticQuery(graphql`
    query {
      page: wpPage(id: {eq: "cG9zdDo1NQ=="}) {
        id
        slug
        title
        content
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  `);

  return (
    <Layout isResources={true}>
      <Seo post={data.page} />
      <CaseStudiesWrap>
        <CaseStudiesContent className="wp-front">
          {!!data.page.content && (
            parse(data.page.content)
          )}
        </CaseStudiesContent>
      </CaseStudiesWrap>
    </Layout>
  );
};

export default CaseStudies;